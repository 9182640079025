import React from 'react'
import { Container, P, StyledLink } from './styles'

const Lower = () => (
  <Container>
    <P>
      <StyledLink to="/sitemap.xml">Sitemap</StyledLink>
    </P>
    <P>Copyright &copy; {new Date().getFullYear()} &middot; Colin Cooke Carpets &amp; Son</P>
  </Container>
)

export default Lower
