import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import Menu from '../../components/menu'
import Header from '../../components/header'
import Footer from '../../components/footer'
import { Main } from './styles'

const MainLayout = ({ titleText, subtitleText, headerCta, children }) => {
  const [showMenu, setShowMenu] = useState(false)
  const toggleMenu = () => setShowMenu(!showMenu)

  if (typeof document !== `undefined`) document.body.style.overflow = 'unset'

  return (
    <Fragment>
      <Menu show={showMenu} toggle={toggleMenu} />
      <Header titleText={titleText} subtitleText={subtitleText} cta={headerCta} toggleMenu={toggleMenu} />
      <Main>{children}</Main>
      <Footer />
    </Fragment>
  )
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
  titleText: PropTypes.string,
  subtitleText: PropTypes.string,
  headerCta: PropTypes.object
}

export default MainLayout
