import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { shade } from 'polished'
import { Link } from 'gatsby'

export const Container = styled.div`
  padding: 1rem;
  /* background-color: #363636; */
  background-color: ${shade(0.5, '#BF2D38')};

  ${breakpoint('desktop')`
    padding: 0.75rem 6rem;
  `}
`

export const Div = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5rem;
`

export const Logos = styled.div`
  display: flex;
`

export const Img = styled.img`
  max-width: 150px;
  max-height: 150px;
`

export const StyledLink = styled(Link)`
  &:not(:last-child) {
    margin-right: 3rem;
  }
`
