import React from 'react'
import { StyledLink, StyledA, StyledButton } from './styles'

const Button = props => {
  if (props.to) return <StyledLink {...props}>{props.text}</StyledLink>
  else if (props.href) return <StyledA {...props}>{props.text}</StyledA>
  else if (props.type === "submit") return <StyledButton {...props}>{props.text}</StyledButton>
  else return null
}

export default Button
