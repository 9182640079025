import React from 'react'
import { NavList, NavItem, NavLink } from './styles'

const Nav = () => (
  <NavList>
    <NavItem>
      <NavLink to="/" activeClassName="active">Home</NavLink>
    </NavItem>

    <NavItem>
      <NavLink to="/about" activeClassName="active">About Us</NavLink>
    </NavItem>

    <NavItem>
      <NavLink to="/gallery" activeClassName="active">Gallery</NavLink>
    </NavItem>

    {/* <NavItem>
      <NavLink to="/testimonials" activeClassName="active">Testimonials</NavLink>
    </NavItem> */}

    <NavItem>
      <NavLink to="/contact" activeClassName="active">Contact Us</NavLink>
    </NavItem>
  </NavList>
)

export default Nav
