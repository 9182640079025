import React from 'react'
import { Container } from './styles'
import Nav from '../nav'

const Upper = () => (
  <Container>
    <Nav />
  </Container>
)

export default Upper
