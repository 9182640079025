import React from 'react'
import { Link } from 'gatsby'
import Logo from '../logo'
import Button from '../button'
import Title from '../title'
import { Container, Top, MenuButton } from './styles'

const Header = (props) => (
  <Container {...props}>
    <Top {...props}>
      <Link to="/" aria-label="Home">
        <Logo />
      </Link>
      <MenuButton onClick={props.toggleMenu}>&#9776;</MenuButton>
    </Top>
    {props.titleText && <Title variant="h1" text={props.titleText} white left wrap />}
    {props.subtitleText && <Title variant="h3" text={props.subtitleText} white left />}
    {props.cta && <Button {...props.cta} primary large />}
  </Container>
)

export default Header
