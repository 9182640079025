import React from 'react'
import Upper from './upper'
import Mid from './mid'
import Lower from './lower'
import { Container } from './styles'

const Footer = () => (
  <Container>
    <Upper />
    <Mid />
    <Lower />
  </Container>
)

export default Footer
