import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { Link } from 'gatsby'

export const Container = styled.div`
  padding: 2rem 6rem;
  background-color: #363636;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${breakpoint('desktop')`
    flex-direction: row;
  `}
`

export const Img = styled.img`
  width: 200px;
  height: auto;

  ${breakpoint('tablet')`
    width: auto;
    height: 100px;
  `}
`

export const Nicf = styled.img`
  background-color: white;
  padding: 1rem;
  width: calc(200px - 2rem);
  height: auto;

  ${breakpoint('tablet')`
    width: auto;
    height: calc(100px - 2rem);
  `}
`

export const StyledLink = styled(Link)`
  margin: 0 0 2rem 0;

  ${breakpoint('desktop')`
    margin: 0 2rem 0 0;
  `}
`
