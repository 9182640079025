import React from 'react'
import { Container, Img, Nicf, StyledLink } from './styles'
import logo from '../../../images/logo.png'
import nicf from '../../../images/nicf.png'

const Mid = () => (
  <Container>
    <StyledLink to="/" aria-label="Home">
      <Img src={logo} alt="Colin Cooke Carpets & Son" />
    </StyledLink>
    <a href="https://nicfltd.org.uk" target="_blank" rel="noopener noreferrer">
      <Nicf src={nicf} alt="NICF National Institute of Carpet & Floorlayers" />
    </a>
  </Container>
)

export default Mid
