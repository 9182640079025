import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { shade } from 'polished'
import { Link } from 'gatsby'

export const Container = styled.div`
  background-color: #BF2D38;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  user-select: none;
  padding: 1rem;

  ${breakpoint('tablet')`
    padding: 1rem 1rem 3rem 1rem;
  `}

  ${breakpoint('desktop')`
    padding: 2rem 5rem 3rem 5rem;
  `}

  ${props => !props.show && css`
    display: none;
  `}

  ${props => props.show && css`
    display: block;
  `}
`

export const CloseButton = styled.span`
  color: white;
  font-size: 2rem;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
  font-weight: bold;

  &:hover {
    color: ${shade(0.5, '#BF2D38')};
  }
`

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 2rem;

  ${breakpoint('desktop')`
    margin-bottom: 5rem;
  `}
`

export const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const NavItem = styled.li`
  padding: 1rem;
`

export const NavLink = styled(Link)`
  padding: 1rem;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2rem;
  transition: 0.2s;

  &:hover {
    color: ${shade(0.5, '#BF2D38')};
  }

  &.active {
    color: ${shade(0.5, '#BF2D38')};
    cursor: default;
    pointer-events: none;
  }
`
