import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export const baseStyles = css`
  display: block;
  margin: 1rem 0;
  width: 100%;

  ${props => props.wrap && css`
    word-wrap: break-word;
    hyphens: auto;
  `}

  ${props => props.marginless && css`
    margin: 0;
  `}

  border-radius: 1rem;
  font-weight: bold;
  padding: 0;
  text-align: center;
  color: #BF2D38;
  font-family: "Merit";
  text-transform: uppercase;

  ${breakpoint('tablet')`
    letter-spacing: 1px;
  `}

  ${breakpoint('desktop')`
    letter-spacing: 2px;
  `}

  ${props => props.white && css`
    color: white;
  `}

  ${props => props.left && css`
    text-align: center;

    ${breakpoint('tablet')`
      text-align: left;
    `}
  `}

  ${props => props.reversed && css`
    ${breakpoint('desktop')`
      padding: 1rem;
      background-color: #BF2D38;
      color: white;
    `}
  `}
`

export const StyledH1 = styled.h1`
  ${baseStyles};

  font-size: 2rem;

  ${breakpoint('tablet')`
    font-size: 3rem;
  `}

  ${breakpoint('desktop')`
    font-size: 4rem;
  `}
`

export const StyledH2 = styled.h2`
  ${baseStyles};

  font-size: 1.5rem;

  ${breakpoint('tablet')`
    font-size: 2.25rem;
  `}

  ${breakpoint('desktop')`
    font-size: 3rem;
  `}
`

export const StyledH3 = styled.h3`
  ${baseStyles};

  font-size: 1.25rem;

  ${breakpoint('tablet')`
    font-size: 1.875rem;
  `}

  ${breakpoint('desktop')`
    font-size: 2.5rem;
  `}
`
