import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { Link } from 'gatsby'

export const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${breakpoint('desktop')`
    flex-direction: row;
    align-items: unset;
  `}
`

export const NavItem = styled.li`
  padding: 0.75rem;

  ${breakpoint('desktop')`
    padding: 0.25rem;
  `}
`

export const NavLink = styled(Link)`
  padding: 0.75rem;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2rem;
  transition: 0.2s;

  ${breakpoint('desktop')`
    padding: 0.25rem;
  `}

  &:hover {
    color: #BF2D38;
  }

  &.active {
    color: #BF2D38;
    cursor: default;
    pointer-events: none;
  }
`
