import React, { Component } from 'react'
import { Link } from 'gatsby'
import Logo from '../logo'
import PropTypes from 'prop-types'
import { Container, CloseButton, Top, NavList, NavItem, NavLink } from './styles'

class Menu extends Component {
  componentDidUpdate() {
    const { show } = this.props

    if (!!show) document.body.style.overflow = 'hidden'
    else if (!show) document.body.style.overflow = 'unset'
  }

  render() {
    const { show, toggle } = this.props

    return (
      <Container show={show}>
        <Top>
          <Link to="/">
            <Logo />
          </Link>
          <CloseButton onClick={toggle}>&#10005;</CloseButton>
        </Top>

        <NavList>
          <NavItem>
            <NavLink to="/" activeClassName="active">Home</NavLink>
          </NavItem>

          <NavItem>
            <NavLink to="/about" activeClassName="active">About Us</NavLink>
          </NavItem>

          <NavItem>
            <NavLink to="/gallery" activeClassName="active">Gallery</NavLink>
          </NavItem>

          {/* <NavItem>
            <NavLink to="/testimonials" activeClassName="active">Testimonials</NavLink>
          </NavItem> */}

          <NavItem>
            <NavLink to="/contact" activeClassName="active">Contact Us</NavLink>
          </NavItem>
        </NavList>
      </Container>
    )
  }
}

Menu.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
}

export default Menu
