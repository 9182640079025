import React from 'react'
import { StyledH1, StyledH2, StyledH3 } from './styles'

const Title = props => {
  if (props.variant === 'h1') return <StyledH1 {...props}>{props.text}</StyledH1>
  else if (props.variant === 'h2') return <StyledH2 {...props}>{props.text}</StyledH2>
  else if (props.variant === 'h3') return <StyledH3 {...props}>{props.text}</StyledH3>
  else return null;
}

export default Title
