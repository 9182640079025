import { Link } from 'gatsby'
import { lighten } from 'polished'
import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export const styles = css`
  border: 3px solid transparent;
  display: inline-block;
  text-decoration: none;
  /* border-radius: 290486px; */
  border-radius: 0.5rem;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 0.75rem 2rem;
  margin: 0;
  cursor: pointer;
  transition: 0.2s;

  ${props => props.fullwidthMobile && css`
    width: 100%;

    ${breakpoint('tablet')`
      width: unset;
    `}
  `}

  ${props => props.small && css`
    font-size: 0.9rem;
    padding: 0.5rem 1.5rem;
    border-width: 2px;
  `}

  ${props => props.large && css`
    font-size: 1.1rem;
    padding: 0.8rem 2.1rem;
    border-width: 3px;

    ${breakpoint('tablet')`
      font-size: 1.3rem;
      padding: 0.9rem 2.3rem;
    `}

    ${breakpoint('desktop')`
      font-size: 1.5rem;
      padding: 1rem 2.5rem;
    `}
  `}

  ${props => props.primary && css`
    color: white;
    background-color: #BF2D38;
    border-color: #BF2D38;

    &:hover {
      background-color: ${lighten(0.05, '#BF2D38')};
      border-color: ${lighten(0.05, '#BF2D38')};
    }

    ${props.outlined && css`
      color: #BF2D38;
      background-color: transparent;
      border-color: #BF2D38;

      &:hover {
        background-color: #BF2D38;
        color: white;
      }
    `}
  `}

  ${props => props.white && css`
    color: #1F1F1F;
    background-color: white;
    border-color: #1F1F1F;

    ${props.outlined && css`
      color: white;
      background-color: transparent;
      border-color: white;

      &:hover {
        background-color: white;
        color: #1F1F1F;

        ${props.pinkHover && css`
          background-color: #DE1C85;
          color: white;
          border-color: #DE1C85;
        `}
      }
    `}
  `}

  ${props => props.success && css`
    color: #1F1F1F;
    background-color: #15C912;
    border-color: #15C912;

    &:hover {
      background-color: ${lighten(0.05, '#15C912')};
      border-color: ${lighten(0.05, '#15C912')};
    }

    ${props.outlined && css`
      color: #15C912;
      background-color: transparent;
      border-color: #15C912;

      &:hover {
        background-color: #15C912;
        color: #1F1F1F;
      }
    `}
  `}
`

export const StyledLink = styled(Link)`
  ${styles}
`

export const StyledA = styled.a`
  ${styles}
`

export const StyledButton = styled.button`
  ${styles}
`
