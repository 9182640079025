import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { Link } from 'gatsby'

export const Container = styled.div`
  padding: 1rem;
  color: white;
  background-color: #1F1F1F;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  ${breakpoint('desktop')`
    padding: 1rem 6rem;
    flex-direction: row;
    align-items: unset;
  `}
`

export const P = styled.p`
  margin: 0;
  padding: 0;
  font-size: 0.9rem;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 1rem;

    ${breakpoint('desktop')`
      margin-bottom: 0;
    `}
  }
`

export const linkStyles = css`
  color: white;
  text-decoration: none;
  transition: 0.2s;

  &:hover {
    color: #BF2D38;
  }
`

export const StyledA = styled.a`
  ${linkStyles}
`

export const StyledLink = styled(Link)`
  ${linkStyles}
`
