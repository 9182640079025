import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { transparentize } from 'polished'
import image from '../../images/gallery/5.jpg'

export const Container = styled.header`
  background-color: #BF2D38;
  background-image: url('${image}');
  background-image: linear-gradient(to top, ${transparentize(0.8, '#000000')}, ${transparentize(0.8, '#000000')}), url('${image}');
  background-size: cover;
  background-position: top;
  padding: 1rem 1rem 3rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${breakpoint('tablet')`
    display: block;
    padding: 2rem;

    ${props => props.cta && css`
      padding-bottom: 4rem;
    `}
  `}

  ${breakpoint('desktop')`
    display: block;
    padding: 2rem 5rem 3rem 5rem;

    ${props => props.cta && css`
      padding-bottom: 8rem;
    `}
  `}
`

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 2rem;

  ${breakpoint('desktop')`
    margin-bottom: 5rem;
  `}

  ${props => props.cta && css`
    margin-bottom: 3rem;

    ${breakpoint('desktop')`
      margin-bottom: 8rem;
    `}
  `}
`

export const MenuButton = styled.span`
  color: white;
  font-size: 2rem;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;

  &:hover {
    color: #BF2D38;
  }
`
